import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class GuestInventoryService {

    async parkingList() {
        promise =  api.client.get(config.end_point.facility.parkings+`?paginate=1&per_page=10&airport_id=${config.airport_id}&online_status=1`);
        return promise;
    }

    async getReserved() {
        promise =  api.client.get(config.end_point.facility.reserve, {parking_id: 1});
        return promise;
    }
}

export const guestInventoryService = new GuestInventoryService();
