import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class GuestDashboardService {
    list(params) {
        promise =  api.client.get(config.end_point.guest.reservations + '?' + params);
        return promise;
    }
}

export const guestDashboardService = new GuestDashboardService();
