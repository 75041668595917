import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class FacilitySettingCalendarService {
    upload(data) {
        promise = api.client.post(config.end_point.facility.airport.calendar_import, data);
        return promise;
    }
    
    update(id, data) {
        promise = api.client.put(config.end_point.facility.airport.calendar + '/' + id, data);
        return promise;
    }

    async list(data) {
        promise = await api.client.get(config.end_point.facility.airport.calendar, {params: data});
        return promise;
    }

    store(data) {
        promise =  api.client.post(config.end_point.facility.airport.calendar, data);
        return promise;
    }

    delete(id) {
        promise = api.client.delete(config.end_point.facility.airport.calendar + '/' + id);
        return promise;
    }
}

export const facilitySettingCalendarService = new FacilitySettingCalendarService();
