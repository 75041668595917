<template>
  <div>
    <div v-if="!error">
      <v-select
        dense
        outlined
        v-model="select_parking"
        :items="parkings"
        item-text="name"
        item-value="id"
        class="mx-10 mt-10"
      />
      <v-sheet height="64">
        <v-toolbar flat>
          <v-row>
            <v-col cols="2" class="d-flex justify-center align-center">
              <v-btn text small color="grey darken-2" @click="prev()">
                <v-icon small>
                  mdi-chevron-left
                </v-icon>
                <strong class="light-blue--text">
                  {{ value | previousMonth }}
                </strong>
              </v-btn>
            </v-col>
            <v-col class="d-flex justify-center align-center">
              <v-toolbar-title v-if="$refs.calendar">
                <div class="text-caption d-flex justify-center align-center mt-n1">
                  {{ $refs.calendar.title | extractYear}}
                </div>
                <div class="text-h6 d-flex justify-center align-center mt-n1">
                  <b>{{ $refs.calendar.title | extractMonth }}</b>
                </div>
              </v-toolbar-title>
            </v-col>
            <v-col cols="2" class="d-flex justify-center align-center">
              <v-btn text small color="grey darken-2" @click="next()">
                <strong class="light-blue--text">
                  {{ value | nextMonth }}
                </strong>
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          v-model="value"
          ref="calendar"
          locale="ja-jp"
          :events="parking_lot[select_parking-1]"
          :event-height="70"
          @change="changeColor"
          :day-format="(timestamp) => new Date(timestamp.date).getDate()"
          :month-format="(timestamp) => (new Date(timestamp.date).getMonth() + 1) + ' /'"
        >
          <template v-slot:event="{ event }">
            <v-sheet class="d-flex align-center justify-center">
              <v-icon v-if="event.end == 0" class="ma-5" large color="cyan accent-2">
                mdi-circle-outline
              </v-icon>
              <v-icon v-if="event.end == 1" class="ma-5" large color="green">
                mdi-triangle-outline
              </v-icon>
              <v-icon v-if="event.end == 2" class="ma-5" large>
                mdi-close
              </v-icon>
            </v-sheet>
          </template>
        </v-calendar>
      </v-sheet>
    </div>
    <div v-else>
      <v-alert dense outlined type="error" class="ma-10">
      エラーが発生しました。駐車場予約エリアの空き状況を取得することができませんでした。<br>
      An error occurred. We were unable to retrieve the availability status for the parking reservation area.
    </v-alert>
    </div>
  </div>
</template>

<script>
import {alertService} from "@/services/alert/alert.service";
import config from "@/config/app.config";
import {guestTopParkingService} from "@/services/guest/top/parking.service";
export default {
  data() {
    return {
      value: '',
      parking_lot:[],
      parkings:[],
      select_parking: 1,
      error: false
    };
  },
  filters: {
    previousMonth(date) {
      const currentDate = new Date(date);
      currentDate.setMonth(currentDate.getMonth());
      return `${currentDate.getMonth()}月`;
    },
    nextMonth(date) {
      const currentDate = new Date(date);
      currentDate.setMonth(currentDate.getMonth());
      return `${currentDate.getMonth() + 2}月`;
    },
    extractMonth(dateString) {
      if (!dateString) return '';
      const [month, year] = dateString.split(' ');
      return month;
    },
    extractYear(dateString) {
      if (!dateString) return '';
      const [month, year] = dateString.split(' ');
      return `${year}年`;
    },
  },
  methods: {
    async get(start, end) {
      await alertService.loading();
      this.parking_lot = [];
      const params = {
        airport_id: config.airport_id,
        entry_date: start,
        exit_date: end
      }
      const response = await guestTopParkingService.list(params).then(async (response) => {
        this.parkings = response.data
        for (const element of response.data) {
          const ary = []
          for (const [key, value] of Object.entries(element.slot)) {
            ary.push({ start: key, end: Number(value) });
          }
          this.parking_lot.push(ary)
        }
        this.error = false;
      }).catch(error => {
        console.log(error);
        this.error = true;
      }).finally(() => {
        alertService.close();
      });
    },
    prev () {
      this.$refs.calendar.prev()
      this.getCapacity(this.value)
    },
    next () {
      this.$refs.calendar.next()
      this.getCapacity(this.value)
    },
    getCapacity(day) {
      const currentDate = new Date(day);
      const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      const formattedStartOfMonth = formatDate(startOfMonth);
      const formattedEndOfMonth = formatDate(endOfMonth);
      this.get(formattedStartOfMonth, formattedEndOfMonth)
    },
    changeColor() {
      setTimeout(() => {
        const weekList = Array.from(this.$refs.calendar.$vnode.elm.getElementsByClassName('v-calendar-weekly__week'))
        weekList.map(week => {
          const sundayLabel = week.children[0].querySelector('.v-calendar-weekly__day-label')
          if (sundayLabel) {
            sundayLabel.classList.add('sunday')
          }
          const saturdayLabel = week.children[6].querySelector('.v-calendar-weekly__day-label')
          if (saturdayLabel) {
            saturdayLabel.classList.add('saturday')
          }
        })
      }, 200)
    },
  },
  mounted(){
    this.changeColor();
    this.getCapacity(new Date());
    this.value = this.$refs.calendar.start
  }
}
</script>

<style>
  .saturday {
    background: #B9F4FF !important;
  }
  .sunday {
    background: #FFB4B4 !important;
  }
  .v-calendar-weekly__day-label {
    height: 25px;
    margin-top: -2px;
    background: rgb(232, 232, 232);
  }
  .v-calendar-weekly__day-label .v-btn {
    height: 25px;
    margin-top: -1px;
  }
  .v-event {
    margin-left: 1px;
  }
</style>
