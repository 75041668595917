import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class GuestTopParkingService {
    async list(params) {
        promise = api.client.get(config.end_point.guest.parkings_slot, {params: params});
        return promise;
    }
}

export const guestTopParkingService = new GuestTopParkingService();
