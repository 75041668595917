import { guestInventoryService } from "@/services/guest/inventory/inventory.service";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar, { sortEventSegs } from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import { EventBus } from "../../../../event-bus";
import { alertService } from "@/services/alert/alert.service";
// var today = new Date().toISOString().slice(0, 10);
import moment from "moment";
import "moment-timezone";
import { facilitySettingCalendarService } from "@/services/facility/setting/calendar.service";

export default {
    components: {
        FullCalendar, // make the <FullCalendar> tag available
    },
    props: ["entry_date", "exit_date"],
    data() {
        return {
            selectParking:1, // カレンダーで選択できる駐車場のid
            minDate: null, // v-date-pickerの予約範囲（開始）
            maxDate: null, // v-date-pickerの予約範囲（終了）
            data: {},
            dataReady: false,
            filter: {
                page: 10,
            },
            currentCalendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: "dayGridMonth",
                selectable: true,
                selectOverlap: true,
                eventClick: this.handleDateSelect,
                select: this.handleDateSelect,
                locales: allLocales,
                locale: this.$i18n.locale,
                longPressDelay: 1,
                contentHeight: "auto",
                headerToolbar: {
                    left: '',
                    center: 'title',
                    right: ''
                },
                selectAllow: (selectInfo) => {
                    return (
                        moment(
                            moment()
                                .tz("Asia/Tokyo")
                                .format("YYYY-MM-DD")
                        ).diff(selectInfo.start) <= 0
                    );
                },
                validRange: {
                    start: moment()
                        .tz("Asia/Tokyo")
                        .format("YYYY-MM-DD"),
                },
                // To remove unnecessary characters
                dayCellContent: function (arg) {
                    return arg.date.getDate();
                },
            },
            nextCalendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: "dayGridMonth",
                initialDate: moment().add(1, 'month').startOf('month').format("YYYY-MM-DD"),
                selectable: true,
                selectOverlap: true,
                eventClick: this.handleDateSelect,
                select: this.handleDateSelect,
                locales: allLocales,
                locale: this.$i18n.locale,
                longPressDelay: 1,
                contentHeight: "auto",
                headerToolbar: {
                    left: '',
                    center: 'title',
                    right: ''
                },
                selectAllow: (selectInfo) => {
                    return (
                        moment(
                            moment()
                                .tz("Asia/Tokyo")
                                .format("YYYY-MM-DD")
                        ).diff(selectInfo.start) <= 0
                    );
                },
                validRange: {
                    start: moment()
                        .tz("Asia/Tokyo")
                        .format("YYYY-MM-DD"),
                },
                // To remove unnecessary characters
                dayCellContent: function (arg) {
                    return arg.date.getDate();
                },
            },
            currentCalendarData: [],
            nextCalendarData: [],
            stopCurrentBooking: [],
            stopNextBooking: [],
            entryDate: "",
            exitDate: "",
            dates:[],
            selectedParking: "",
            dateError: false,
            dateRangeError: false,
            availableBooking: [],
            availableCurrentBooking: [],
            availableNextBooking: [],
            canClick: false,
            parking_id: null,
            showModal: false
        };
    },
    created() {
        this.setDateRange();
    },
    watch: {
        dates(newDates) {
            this.entryDate = newDates.length > 0 ? newDates[0] : '';
            this.exitDate = newDates.length > 1 ? newDates[1] : '';
            if (newDates.length === 2) {
                const [entry, exit] = newDates;
                if (new Date(entry) > new Date(exit)) {
                    this.dates = [exit, entry];
                }
            }
        },
    },
    computed: {
        formattedEntryDate() {
            if (!this.entryDate) {
                return '';
            }
            return this.formatDate(this.entryDate);
        },
        formattedExitDate() {
            if (!this.exitDate) {
                return '';
            }
            return this.formatDate(this.exitDate);
        },
        datePickerLocale() {
            return this.$i18n.locale === 'ja' ? 'ja-JP' : 'en';
        },
    },
    async mounted() {
        await this.list();
        await this.getHolidayEvents();
        setTimeout(() => {
            this.entryDate = this.entry_date;
            this.exitDate = this.exit_date;
        }, 500);

        EventBus.$on("changeLocal", () => {
            this.getHolidayEvents();
            this.currentCalendarData.forEach((item) => {
                item.locale = this.$i18n.locale;
            });

            this.nextCalendarData.forEach((item) => {
                item.locale = this.$i18n.locale;
            })

            this.currentCalendarOptions.locale = this.$i18n.locale;
            this.nextCalendarOptions.locale = this.$i18n.locale;
        });
    },
    methods: {
        // 予約できる範囲を作成
        setDateRange() {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const oneMonthBefore = new Date(today);
            oneMonthBefore.setMonth(oneMonthBefore.getMonth());
            const oneMonthAhead = new Date(today);
            oneMonthAhead.setMonth(oneMonthAhead.getMonth() + 1);
            this.minDate = oneMonthBefore.toISOString().substr(0, 10);
            this.maxDate = oneMonthAhead.toISOString().substr(0, 10);
        },
        // 当日以前の開始日設定を非活性化
        allowedDates(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0); // 今日の0時0分0秒に設定

            const oneMonthAhead = new Date();
            oneMonthAhead.setMonth(oneMonthAhead.getMonth() + 1);
            oneMonthAhead.setHours(0, 0, 0, 0); // 1か月先の0時0分0秒に設定

            const selectedDate = new Date(date);
            return selectedDate >= today && selectedDate < oneMonthAhead;
        },


        formatDate(dateString) {
            const date = new Date(dateString);
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土'];
            const dayOfWeek = daysOfWeek[date.getDay()];
            return `${month}月${day}日（${dayOfWeek}）`;
        },
        showErrorModal() {
            this.$bvModal.show('error-modal');
        },
        hideErrorModal() {
            this.$bvModal.hide('error-modal');
        },
        async list() {
            alertService.loading();
            this.dataReady = false;
            await guestInventoryService.parkingList().then((response) => {
                this.data = response.data;
                this.dataReady = true;

                let events = [];
                response.data.data.forEach((inventory) => {

                    inventory.reservable_capacities.forEach((capacity) => {

                        if (capacity && capacity.month == "current") {

                            let input = this.event_metadata(
                                capacity,
                                inventory.inventory_symbols,
                                true
                            );

                            //title,
                            events.push({
                                start: capacity.date,
                                end: capacity.date,
                                title: capacity.capacity,
                                parkingId: inventory.id,
                                reserved: 0,
                                extendedProps: input,
                                month: capacity.month
                            });

                            this.availableCurrentBooking.push({
                                ...capacity,
                                parking_id: inventory.id,
                            });

                        } else if (capacity.month == "next") {
                            let input = this.event_metadata(
                                capacity,
                                inventory.inventory_symbols,
                                true
                            );

                            events.push({
                                start: capacity.date,
                                end: capacity.date,
                                title: capacity.capacity,
                                parkingId: inventory.id,
                                reserved: 0,
                                extendedProps: input,
                                month: capacity.month
                            });



                            this.availableNextBooking.push({
                                ...capacity,
                                parking_id: inventory.id,
                            });
                        }
                    });

                    inventory.stop_booking.forEach((stop_booking) => {

                        if (stop_booking.month === "current") {

                            let input = this.event_metadata(
                                stop_booking,
                                inventory.inventory_symbols,
                                false
                            );

                            events.push({
                                start: stop_booking.date,
                                end: stop_booking.date,
                                title: stop_booking.capacity,
                                parkingId: inventory.id,
                                reserved: 0,
                                extendedProps: input,
                                month: stop_booking.month
                            });

                            this.stopCurrentBooking.push({
                                date: stop_booking.date,
                                parking_id: inventory.id,
                            });
                        } else if (stop_booking.month === "next") {
                            let input = this.event_metadata(
                                stop_booking,
                                inventory.inventory_symbols,
                                false
                            );

                            events.push({
                                start: stop_booking.date,
                                end: stop_booking.date,
                                title: stop_booking.capacity,
                                parkingId: inventory.id,
                                reserved: 0,
                                extendedProps: input,
                                month: stop_booking.month
                            });

                            this.stopNextBooking.push({
                                date: stop_booking.date,
                                parking_id: inventory.id,
                            });
                        }

                    });

                    this.setReserved(events, inventory.id);
                    events = [];
                });
                alertService.close();
            })
            .finally(() => {
                // 【エラーのコンフリクト】guestページでローディングが終わらない部分の対応
                alertService.close();
            });
        },

        async setReserved(events, id) {

            guestInventoryService.getReserved().then((response) => {
                if (typeof response.data[id] !== "undefined") {
                    response.data[id].forEach((reserve) => {
                        events.forEach((event) => {
                            if (reserve.date == event.start) {
                                event.reserved = reserve.total;
                            }
                        });
                    });
                }
            });

            let currentEvents = [];
            let nextEvents = [];

            events.forEach(event => {
                if (event.month === 'current') {
                    currentEvents.push(event);
                } else if (event.month === 'next') {
                    nextEvents.push(event);
                }
            });

            this.currentCalendarData.push({
                events: currentEvents,
                ...this.currentCalendarOptions,
                ...{ eventContent: this.reRenderCalendar },
            });

            this.nextCalendarData.push({
                events: nextEvents,
                ...this.nextCalendarOptions,
                ...{ eventContent: this.reRenderCalendar },
            });
        },

        reRenderCalendar(arg) {
            let metadata = arg.event.extendedProps;

            this.stopCurrentBooking.forEach((list) => {
                if (
                    list.parking_id == arg.event.extendedProps.parkingId &&
                    list.date == arg.event.startStr
                ) {
                    $(`.parking-id-${list.parking_id}-current`)
                        .find(`[data-date='${arg.event.startStr}']`)
                        .css({
                            background: metadata.color,
                            cursor: "not-allowed",
                        })
                        .addClass(metadata.symbol);
                }
            });

            this.stopNextBooking.forEach((list) => {
                if (
                    list.parking_id == arg.event.extendedProps.parkingId &&
                    list.date == arg.event.startStr
                ) {
                    $(`.parking-id-${list.parking_id}-next`)
                        .find(`[data-date='${arg.event.startStr}']`)
                        .css({
                            background: metadata.color,
                            cursor: "not-allowed",
                        })
                        .addClass(metadata.symbol);
                }
            });


            this.availableCurrentBooking.forEach((list) => {
                if (
                    list.parking_id == arg.event.extendedProps.parkingId &&
                    list.date == arg.event.startStr
                ) {
                    $(`.parking-id-${list.parking_id}-current`)
                        .find(`[data-date='${arg.event.startStr}']`)
                        .css({
                            background: metadata.color,
                            cursor:
                                metadata.color == "#f5c6cb"
                                    ? "not-allowed"
                                    : "pointer",
                        })
                        .addClass(metadata.symbol);
                }
            });

            this.availableNextBooking.forEach((list) => {
                if (
                    list.parking_id == arg.event.extendedProps.parkingId &&
                    list.date == arg.event.startStr
                ) {
                    $(`.parking-id-${list.parking_id}-next`)
                        .find(`[data-date='${arg.event.startStr}']`)
                        .css({
                            background: metadata.color,
                            cursor:
                                metadata.color == "#f5c6cb"
                                    ? "not-allowed"
                                    : "pointer",
                        })
                        .addClass(metadata.symbol);
                }
            });

            // As icon or background
            if (metadata.holiday) {
                return {
                    html: `<span class="badge badge-secondary calendar-event-name">${arg.event.title}</span>`,
                };
            } else {
                return { html: `<div></div>` };
            }
            // return { html: `<div>${metadata.icon}</div>` };
        },
        handleDateSelect(selectInfo) {
            // Added to make event clickable
            var startStr = selectInfo.startStr || selectInfo.event.startStr;
            var start = selectInfo.start || selectInfo.event.start;

            let enable = false;
            let parkingId =
                    selectInfo.view.calendar.currentData.calendarOptions.events[0]
                    .parkingId;


            this.parking_id = parkingId;
           
            let availableBooking = [
                ...this.availableCurrentBooking,
                ...this.availableNextBooking
            ];

            let stopBooking = [
                ...this.stopCurrentBooking,
                ...this.stopNextBooking
            ];

            availableBooking.forEach((available) => {
                if (
                    available.date == startStr &&
                    available.parking_id == parkingId &&
                    available.remaining_slot > 0
                ) {
                    enable = true;
                    return;
                }
            });


            // Function to check if the date is valid for selection
            const isValidDate = (dateStr) => {
                const booking = availableBooking.find(
                    (available) => available.date === dateStr && available.parking_id === parkingId
                );
                return booking && booking.remaining_slot > 0;
            };

            // Function to check if any date in the range is invalid
            const isRangeValid = (startDateStr, endDateStr) => {
                const startDate = moment(startDateStr, "YYYY/MM/DD");
                const endDate = moment(endDateStr, "YYYY/MM/DD");

                for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'days')) {
                    const dateStr = date.format("YYYY-MM-DD");
                    if (!isValidDate(dateStr)) return false;
                    if (stopBooking.some(stop => stop.date === dateStr && stop.parking_id === parkingId)) return false;
                }
                return true;
            };

            //navigate to next screen after selection of startdate adn exitdate
            const navigateToNextScreen = () => {

                const parking = this.data.data.find((el) => 
                    el.id == this.parking_id  
                );

                const parking_name = parking.name;
                const parking_type = parking.parking_type

                this.makeReservation(parking_name, this.parking_id, parking_type)
            };

             // Handle date selection
             if (!this.entryDate) {
                if (isValidDate(startStr)) {
                  this.entryDate = startStr;
                  this.exitDate = "";
                } else {
                  this.showErrorModal();
                }
              } else if (!this.exitDate) {
                this.exitDate = startStr;
            
                if (moment(this.entryDate).isAfter(this.exitDate)) {
                  [this.entryDate, this.exitDate] = [this.exitDate, this.entryDate];
                }
            
                if (!isRangeValid(this.entryDate, this.exitDate)) {
                  this.showErrorModal();
                  this.entryDate = "";
                  this.exitDate = "";
                } else {
                  navigateToNextScreen();
                }
              } else {
                this.entryDate = startStr;
                this.exitDate = "";
              }

            // Prevent selecting date range if included block and no remaining slot dates
            if (
                this.entryDate &&
                this.exitDate &&
                this.entryDate != this.exitDate
            ) {
                // For no remaining slot
                availableBooking.forEach((available) => {
                    var compareDate = moment(available.date, "YYYY/MM/DD");
                    var startDate = moment(this.entryDate, "YYYY/MM/DD");
                    var endDate = moment(this.exitDate, "YYYY/MM/DD");
                    if (
                        compareDate.isBetween(startDate, endDate) &&
                        available.parking_id == parkingId &&
                        available.remaining_slot == 0
                    ) {
                        this.entryDate = "";
                        this.exitDate = "";
                        this.dateRangeError = true;
                    }
                });

                // For stop booking
                stopBooking.forEach((stop) => {
                    var compareDate = moment(stop.date, "YYYY/MM/DD");
                    var startDate = moment(this.entryDate, "YYYY/MM/DD");
                    var endDate = moment(this.exitDate, "YYYY/MM/DD");

                    if (
                        compareDate.isBetween(startDate, endDate) &&
                        stop.parking_id == parkingId
                    ) {
                        this.entryDate = "";
                        this.exitDate = "";
                    }
                });
            }
            if (this.entryDate != 0 && this.exitDate != 0) {
                this.dateRangeError = false;
            }
        },
        

        makeReservation(parking_name, parking_id, parking_type) {
            this.dateError = false;
            if (this.entryDate && this.exitDate) {
                window.scrollTo({ top: 0, behavior: "smooth" });
                let data = {
                    parking_id: parking_id,
                    parking_name: parking_name,
                    entry_date: this.entryDate,
                    exit_date: this.exitDate,
                    parking_type: parking_type,
                };
                this.$emit("setDates", data);
            } else {
                this.dateError = true;
            }
        },
        event_metadata(capacity, symbols, status) {
            let input = {
                color: "",
                icon: "",
            };
            let symbol = symbols;
            if (symbol) {
                if (!status) {
                    return {
                        color: "#f5c6cb",
                        icon: "X",
                        symbol: "red-symbol",
                    };
                }
                if (capacity.remaining_slot > symbol.booking_count) {
                    input = {
                        color: "#d0e0f5",
                        icon: "&#9675;",
                        symbol: "blue-symbol",
                    };
                } else if (
                    capacity.remaining_slot <= symbol.booking_count &&
                    capacity.remaining_slot > 0
                ) {
                    input = {
                        color: "#ffeeba",
                        icon: "&#9651;",
                        symbol: "yellow-symbol",
                    };
                } else if (capacity.remaining_slot == 0 || !status) {
                    input = {
                        color: "#f5c6cb",
                        icon: "X",
                        symbol: "red-symbol",
                    };
                }
                if (!symbol.is_color_indicates) {
                    input.color = "";
                }
            }
            return input;
        },
        isSelected(id) {
            if (!this.parking_id) {
                return false;
            }
            let status = false;

            if (id != this.parking_id) {
                status = true;
            }

            return status;
        },
        async getHolidayEvents() {
            const result = await facilitySettingCalendarService.list({
                paginate: 0,
            });
        
            if (result.data.length > 0) {
                const nextMonthStart = moment().add(1, 'month').startOf('month');
                const nextMonthEnd = moment().add(1, 'month').endOf('month');
        
                result.data.forEach((value) => {
                    const eventDate = moment(value.date);
                    const title = this.$i18n.locale == "en" ? value.name_display_en : value.name_display_jp;
                    const event = {
                        start: value.date,
                        end: value.date,
                        title: title,
                        extendedProps: {
                            holiday: true,
                        },
                    };
        
                    if (eventDate.isSameOrAfter(moment().startOf('month')) && eventDate.isBefore(nextMonthStart)) {
                        // Event is in the current month
                        this.currentCalendarData.forEach((calendar) => {
                            let index = calendar.events.findIndex(
                                (e) => e.start == value.date && e.extendedProps.holiday
                            );
                            if (index != -1) {
                                calendar.events[index].title = title;
                            } else {
                                calendar.events.push(event);
                            }
                        });
                    } else if (eventDate.isSameOrAfter(nextMonthStart) && eventDate.isSameOrBefore(nextMonthEnd)) {
                        // Event is in the next month
                        this.nextCalendarData.forEach((calendar) => {
                            let index = calendar.events.findIndex(
                                (e) => e.start == value.date && e.extendedProps.holiday
                            );
                            if (index != -1) {
                                calendar.events[index].title = title;
                            } else {
                                calendar.events.push(event);
                            }
                        });
                    }
                });
            }
        }
        
       
    },
};
