import {guestDashboardService} from "@/services/guest/dashboard/dashboard.service";
import {alertService} from "@/services/alert/alert.service";
import InventoryCalendar from '../inventory/inventory.component.vue'
import Capacity from "@/resources/components/guest/capacity.component.vue";

export default {
    components: {
        InventoryCalendar,
        Capacity,
    },
    data: () => {
        return {
            data: {},
            dataReady: false,
            filter: {
                page: 1
            }
        }
    },
    async mounted() {
        this.list()
    },
    methods: {

        async list() {
            alertService.loading();
            this.dataReady = false
            await guestDashboardService.list('paginate=1&per_page=10&status=0').then((response) => {
                this.data = response.data
                this.dataReady = true
                alertService.close();
            }).catch((error) => {
                console.log(error)
            });

        }
    }

}
